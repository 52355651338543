<template>
  <v-input :error-messages="errorMessages">
    <v-row no-gutters class="flex-nowrap">
      <v-col class="flex-shrink-1">
        <v-autocomplete
          item-value="callingCode"
          item-title="callingCode"
          v-model="callingCode"
          :items="callingCodes"
          :label="$t('fields.prefix')"
          hide-details
          @change="emitChange"
          :loading="loading"
          :filter="filter"
          :error="errorMessages.length > 0"
          @blur="$emit('blur')"
        >
          <template v-slot:item="{ item, props }">
            <v-list-item v-bind="props" :title="`${item.raw.callingCode} (${item.raw.name})`">
              <template #prepend><CountryFlag class="mr-1" :iso="item.raw.id" /></template>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <CountryFlag class="mr-1" v-if="item.raw" :iso="item.raw.id"></CountryFlag> {{ item.raw?.callingCode }}
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="auto" class="flex-grow-1">
        <v-text-field
          pattern="[0-9]{10}"
          v-model="phoneNumberValue"
          :label="label"
          hide-details
          @update:model-value="emitChange"
          :error="errorMessages.length > 0"
          @blur="$emit('blur')"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-input>
</template>

<script lang="ts" setup>
import { useQuery } from "@vue/apollo-composable";
import { computed, ref, watch } from "vue";
import { BaseModel } from "@/bytenest-cli/definitions";
// @ts-expect-error import
import CountryFlag from "@dzangolab/vue-country-flag-icon";
import "@dzangolab/vue-country-flag-icon/dist/CountryFlag.css"; // import stylesheet
import { CountriesDocument } from "@/gql/graphql";

defineProps({
  label: String,
  errorMessages: { type: Array<string>, default: () => [] },
});

defineEmits<{
  (e: "blur"): void;
}>();

const model = defineModel<string | null>();

const { result, loading, onResult } = useQuery(CountriesDocument);

const callingCodes = computed(() => result?.value?.countries ?? []);

const callingCode = ref("");
const phoneNumberValue = ref("");

watch(model, (newValue) => {
  if (newValue) extractPhoneNumber(newValue);
});

onResult(({ loading }) => {
  if (model.value && !loading) extractPhoneNumber(model.value);
});

const extractPhoneNumber = (value: string) => {
  const matchingPrefix = callingCodes.value.find(({ callingCode }) =>
    value.startsWith("00" + (callingCode?.substring(1) ?? ""))
  );

  if (matchingPrefix?.callingCode) {
    callingCode.value = matchingPrefix.callingCode;
    phoneNumberValue.value = value.substring(matchingPrefix.callingCode.length + 1);
  } else {
    callingCode.value = "";
    phoneNumberValue.value = value;
  }
};

const emitChange = () => {
  if (!callingCode.value) {
    model.value = phoneNumberValue.value;
    return;
  }

  const formattedCallingCode = "00".concat(callingCode.value.substring(1));
  model.value = formattedCallingCode + phoneNumberValue.value;
};

const filter = (item: BaseModel, queryText: string) => {
  const textOne = item.name.toLowerCase();
  const textTwo = item.callingCode.toLowerCase();
  const searchText = queryText.toLowerCase();
  return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
};
</script>
