import { useApolloClient } from "@vue/apollo-composable";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import { ref } from "vue";
import { Exact } from "@/gql/graphql";
import { BaseModel } from "../definitions";
import { GraphQLError } from "graphql";

interface FormMutationConfig<CreateMutationType, UpdateMutationType> {
  model: string;
  create_mutation: DocumentNode<CreateMutationType, Exact<{ input: any }>>;
  update_mutation?: DocumentNode<UpdateMutationType, Exact<{ id: string; input: any }>>;
  preprocessor?: (input: BaseModel) => BaseModel;
}

export function useFormMutations<CreateMutationType, UpdateMutationType>(
  config: FormMutationConfig<CreateMutationType, UpdateMutationType>
) {
  const { create_mutation, update_mutation } = config;
  const { client: apolloClient } = useApolloClient();

  const saving = ref(false);

  async function createModel(
    input: object
  ): Promise<{ data: CreateMutationType | null | undefined; errors: GraphQLError[] }> {
    saving.value = true;

    if (config.preprocessor) {
      input = config.preprocessor(input);
    }

    try {
      const { data } = await apolloClient.mutate({
        mutation: create_mutation,
        variables: { input },
      });
      saving.value = false;
      return { data, errors: [] };
    } catch (error: any) {
      saving.value = false;
      if (process.env.NODE_ENV === "development") console.error(error);
      return { data: null, errors: error.graphQLErrors ?? [] };
    }
  }

  async function updateModel(
    id: string,
    input: object
  ): Promise<{ data: UpdateMutationType | null | undefined; errors: GraphQLError[] }> {
    if (!update_mutation) {
      if (process.env.NODE_ENV === "development") console.error("Missing update_mutation");
      return { data: null, errors: [] };
    }

    saving.value = true;

    if (config.preprocessor) {
      input = config.preprocessor(input);
    }

    try {
      const { data } = await apolloClient.mutate({
        mutation: update_mutation,
        variables: { id, input },
      });
      saving.value = false;
      return { data, errors: [] };
    } catch (error: any) {
      saving.value = false;
      if (process.env.NODE_ENV === "development") console.error(error);
      return { data: null, errors: error.graphQLErrors ?? [] };
    }
  }

  return { createModel, updateModel, saving };
}
