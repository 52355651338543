<template>
  <ApolloQuery
    v-if="field.type == 'query'"
    :query="field.query"
    :variables="apolloParams.variables"
    :fetchPolicy="apolloParams.fetchPolicy"
    :update="field.update"
    :skip="apolloParams.skip"
  >
    <template v-slot="{ result: { data, error } }">
      <v-autocomplete
        :loading="!data && !error && !apolloParams.skip"
        :items="data ? _.get(data, field.path ?? '') : []"
        :item-title="field.text || 'name'"
        :label="label"
        :menu-props="{
          'max-height': 300,
        }"
        :autocomplete="field.autocomplete || 'new-password'"
        :disabled="apolloParams.skip"
        :no-data-text="$t('no_results')"
        :error-messages="errors"
        :hint="hint"
        v-model="value"
        v-bind="field.bind"
        v-on="onProps"
        auto-select-first
        return-object
      />
    </template>
  </ApolloQuery>

  <ApolloQuery
    v-else-if="field.type == 'api'"
    :query="field.query"
    :variables="apolloParams.variables"
    :skip="apolloParams.skip"
  >
    <template v-slot="{ result: { data, error } }">
      <component
        :is="field.allow_unknown ? VCombobox : VSelect"
        :loading="!data && !error"
        :items="data ? createOptions(field, _.get(data, field.path ?? '')) : []"
        :label="label"
        :autocomplete="field.autocomplete || 'new-password'"
        :no-data-text="$t('no_results')"
        :error-messages="errors"
        v-model="value"
        v-bind="field.bind"
        auto-select-first
        v-on="onProps"
      />
    </template>
  </ApolloQuery>

  <datetime-input
    v-else-if="field.type === 'datetime' || field.type === 'date'"
    v-model="value"
    :type="field.type"
    :label="label"
    :textFieldProps="{ 'prepend-icon': 'mdi-calendar', ...field.bind, 'error-messages': errors }"
    v-bind="field.bind"
  />

  <v-date-input
    v-else-if="field.type === 'dateinput'"
    v-model="value"
    :type="field.type"
    :label="label"
    :textFieldProps="{ ...field.bind, 'error-messages': errors }"
    v-bind="field.bind"
  />

  <v-autocomplete
    v-else-if="field.type == 'autocomplete'"
    :label="label"
    :items="field.items"
    v-model="value"
    v-bind="field.bind"
    auto-select-first
    :no-data-text="$t('no_results')"
    :error-messages="errors"
    v-on="onProps"
  />

  <v-autocomplete
    v-else-if="field.type == 'time'"
    :label="label"
    :items="timeSlots"
    v-model="value"
    v-bind="field.bind"
    auto-select-first
    :error-messages="errors"
    :no-data-text="$t('no_results')"
    v-on="onProps"
  />

  <v-text-field
    v-else-if="field.type == 'number'"
    :label="label"
    v-bind="field.bind"
    type="number"
    :model-value="value"
    @update:model-value="value = Number($event)"
    :autocomplete="field.autocomplete || 'new-password'"
    :error-messages="errors"
    v-on="onProps"
  />

  <v-rating
    v-else-if="field.type == 'rating'"
    :label="label"
    v-bind="field.bind"
    v-model="value"
    :error-messages="errors"
    v-on="onProps"
  />

  <v-menu :close-on-content-click="false" v-else-if="field.type == 'color'" transition="scale-transition" offset-y min->
    <template v-slot:activator="{ props }">
      <v-text-field
        v-on="onProps"
        :label="label"
        v-bind="{ ...props, ...field.bind }"
        v-model="value"
        :autocomplete="field.autocomplete || 'new-password'"
        l="true"
        :error-messages="errors"
      >
        <template #append>
          <v-icon size="large" :color="value" icon="mdi-circle" />
        </template>
      </v-text-field>
    </template>
    <v-color-picker
      hide-canvas
      hide-inputs
      :swatches="swatches"
      show-swatches
      v-model="value"
      :label="label"
      :autocomplete="field.autocomplete || 'new-password'"
      v-on="onProps"
    />
  </v-menu>

  <v-textarea
    v-else-if="field.type == 'textarea'"
    :label="label"
    v-bind="field.bind"
    v-model="value"
    :autocomplete="field.autocomplete || 'new-password'"
    :error-messages="errors"
    v-on="onProps"
  />

  <v-file-input
    v-else-if="field.type === 'file'"
    :model-value="(value && [value]) ?? []"
    @update:model-value="$emit('update:modelValue', $event)"
    :label="label"
    :rules="[checkFileSize]"
    :error-messages="errors"
    accept="image/*,.pdf"
    clearable
  />

  <v-checkbox
    v-else-if="field.type === 'checkbox'"
    v-bind="field.bind"
    v-model="value"
    :error-messages="errors"
    v-on="onProps"
  >
    <template #label>
      <span v-html="label"></span>
    </template>
  </v-checkbox>

  <phone-number-input
    v-else-if="field.type === 'phone'"
    :label="label"
    v-bind="field.bind"
    v-model="value"
    :error-messages="errors"
    v-on="onProps"
  />

  <v-text-field
    v-else
    :label="label"
    v-bind="field.bind"
    v-model="value"
    :autocomplete="field.autocomplete || 'new-password'"
    :error-messages="errors"
    v-on="onProps"
  />
</template>

<script lang="ts">
export default {
  name: "BaseInput",
};
</script>

<script setup lang="ts">
import DatetimeInput from "./DatetimeInput.vue";
import PhoneNumberInput from "./PhoneNumberInput.vue";
import { computed, ref, WritableComputedRef, watch } from "vue";
import _ from "lodash";
import { useI18n } from "vue-i18n";
import { useTimeslots } from "../../utils/timeslots";
import { Field } from "@/bytenest-cli/definitions";
import { VCombobox, VSelect } from "vuetify/components";

const { t: $t } = useI18n();

const { getTimeSlots } = useTimeslots();

const swatches = ref([["#d11141"], ["#00b159"], ["#00aedb"], ["#f37735"], ["#ffc425"]]);

const props = withDefaults(
  defineProps<{
    modelValue: any;
    field: Field;
    errors?: string[];
    label?: string;
    apolloParams?: any;
    hint?: string;
  }>(),
  {
    apolloParams: () => ({ variables: {}, skip: false, fetchPolicy: "cache-first" }),
  }
);

const value: WritableComputedRef<any> = computed({
  get: () => props.modelValue,
  set: (v) => emit("update:modelValue", v),
});

const emit = defineEmits(["validate", "update:modelValue"]);

const onProps = {
  blur: () => emit("validate"),
};

function checkFileSize(file: File) {
  if (!file) return true;

  if (file.size > 2e6) return $t("errors.validation.file.size");
  else return true;
}

watch(
  () => props.apolloParams.variables,
  (newValue, oldValue) => {
    if (!_.isEqual(newValue, oldValue)) {
      emit("update:modelValue", null);
    }
  }
);

const timeSlots = computed(() => getTimeSlots("9:00", "23:45"));

function createOptions(field: Field, data: string[]): string[] | { title: string; value: string }[] {
  if (!field.optionFormatter) return data;

  const formatter = field.optionFormatter;

  return data.map((item) => ({ title: formatter(item), value: item }));
}
</script>
